*,
*:before,
*:after {
  box-sizing: inherit;
}

html {
  font-family: "Roboto", Helvetica, sans-serif;
  line-height: 1.6;
  font-size: 1em;
  box-sizing: border-box;
}

html,
body {
  min-height: 100vh;
  color: red; /* To make sure we’re not defaulting any colors */
  padding: 0;
  margin: 0;
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
}

ul, ol { margin: 0; padding: 0; }
li { list-style: none; }
dl, dt, dd { margin: 0; }

a {
  color: inherit;
  text-decoration: underline;
  outline: none;
}

b {
  font-weight: 700;
}

button {
  font-size: inherit;
  outline: none;
  cursor: pointer;
  color: inherit;
  line-height: inherit;
  background: none;
  border: 0;
  padding: 0;
}

button[disabled] {
  cursor: not-allowed;
}

svg {
  display: block;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
}

h1 {
  font-size: 1.5rem;
}

h2 {
  font-size: 1.125rem;
}

h3,
h4,
h5,
h6 {
  font-size: 1rem;
}

p {
  margin: 1rem 0;
}

input,
textarea {
  border: 1px solid #C8C7C7;
  padding: 0.5em;
  outline: none;
}

input[disabled],
textarea[disabled] {
  background: #F6F5F5;
  cursor: not-allowed;
}

select {
  cursor: pointer;
}

.ReactModal__Body--open {
  touch-action: none;
  -webkit-overflow-scrolling: none;
  overflow: hidden;
  overscroll-behavior: none;
}
